<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <v-card>
          <v-card-title>
            <v-btn
              class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
              fab
              small
              @click="createFormEnable"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            item-key="name"
            class="elevation-1"
          >
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item.country_name }}</td>
                <td>{{ props.item.state_name }}</td>
                <td>{{ props.item.city_name }}</td>
                <td style="display: flex; flex-direction: row">
                  <v-btn
                    class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
                    fab
                    small
                    @click="editButtonClick(props.item)"
                  >
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    class="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
                    fab
                    small
                    @click="deleteButtonClick(props.item)"
                  >
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div class="col-md-4">
        <!-- create form start -->
        <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="city"
            :rules="nameRules"
            label="City name"
            required
          ></v-text-field>
          <b-form-select
            v-model="state_id"
            :options="statesOptions"
            size="sm"
            class="mt-3"
          ></b-form-select>
          <div style="margin-top: 5%">
            <v-btn
              class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
              @click="createCity"
            >
              Save
            </v-btn>
            <v-btn
              style="margin-left: 6%"
              class="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </div>
        </v-form>
        <!-- create form end -->

        <!-- update form start -->
        <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="city"
            :rules="nameRules"
            label="City name"
            required
          ></v-text-field>
          <b-form-select
            v-model="state_id"
            :options="statesOptions"
            size="sm"
            class="mt-3"
          ></b-form-select>
          <div style="margin-top: 5%">
            <v-btn
              class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
              @click="updateCity"
            >
              Update
            </v-btn>
            <v-btn
              style="margin-left: 6%"
              class="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </div>
        </v-form>
        <!-- update form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      search: "",
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: false,
      updateForm: false,
      city: "",
      city_id: null,
      state_id: null,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      headers: [
        { text: "Country Name", value: "country_name" },
        { text: "State Name", value: "state_name" },
        { text: "City Name", value: "city_name" },
        { text: "Action", value: "action" },
      ],
      data: [],
      statesOptions: [{ value: null, text: "Please select state" }],
      states: [],
    };
  },
  mounted() {
    this.fetch();
    this.fetchStates();
  },
  methods: {
    cancel() {
      this.createForm = false;
      this.updateForm = false;
      this.city = "";
      this.state_id = null;
    },
    createFormEnable() {
      this.city = "";
      this.createForm = true;
      this.updateForm = false;
    },
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/cities`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchStates() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/states`)
        .then((res) => {
          res.data.forEach((element) => {
            this.statesOptions.push({
              value: element.id,
              text: element.state_name,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    createCity() {
      if ((this.city == "", this.state_id == null)) {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/cities`, {
          state_id: this.state_id,
          city_name: this.city,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.city = "";
          Swal.fire({
            title: "",
            text: "City added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateCity() {
      if ((this.city == "", this.state_id == null)) {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/cities/${this.city_id}`, {
          state_id: this.state_id,
          city_name: this.city,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          (this.city = ""), (this.state_id = null);
          Swal.fire({
            title: "",
            text: "City updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.city = item.city_name;
      this.city_id = item.id;
      this.state_id = item.state_id;
    },
    deleteButtonClick(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/cities/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "City deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
  },
};
</script>
